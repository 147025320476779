<template>
  <a-table :columns="columns" bordered :data-source="dataSource" :pagination="pagination" :row-key="(r,i)=>r.id">
    <template #key="{ index }">
      <span>{{(page.current - 1) * page.pageSize + index + 1}}</span>
    </template>
    <template #kpje="{ record }">
      <span class="price-logo" >{{record.kpje}}</span>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <a-button type="primary" size="small" @click="forwardInvoice(record)">申请开票</a-button>
        <!-- <a-button v-if="record.operation === '1'" size="small" disabled>申请中...</a-button>
        <a-button v-if="record.operation === '2'" size="small" disabled>申请成功</a-button>
        <a-button v-if="record.operation === '3'" type="danger" size="small">重新申请</a-button> -->
      </div>
    </template>
  </a-table>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, computed, getCurrentInstance } from 'vue'
import { listOrder } from '../../api'
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    align: 'center',
    width: 64,
    slots: {
      customRender: 'key'
    }
  },
  {
    title: '项目名称',
    dataIndex: 'p_name',
    align: 'center'
  },
  {
    title: '参展商名称',
    dataIndex: 'corp',
    align: 'center'
  },
  {
    title: '参展商联系人',
    dataIndex: 'corp_contacts',
    align: 'center'
  },
  {
    title: '手机号',
    dataIndex: 'corp_contacts_mobile',
    align: 'center'
  },
  {
    title: '费用金额',
    dataIndex: 'kpje',
    align: 'center',
    slots: {
      customRender: 'kpje'
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
    width: '160px',
    slots: {
      customRender: 'operation'
    }
  }
]

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const dataSource = ref([])
    const page = ref({
      total: 0,
      pageSize: 10,
      current: 1
    })
    const pagination = computed(() => ({
      total: page.value.total,
      current: page.value.current,
      pageSize: page.value.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      onShowSizeChange: pageSizeChange,
      onChange: pageChange
    }))
    const pageSizeChange = (pageNum, pageSize) => {
      // console.log('pageSizeChange');
      init({ pageNum, pageSize })
      page.value.pageSize = pageSize
    }
    const pageChange = (pageNum, pageSize) => {
      // console.log('pageChange');
      init({ pageNum, pageSize })
    }
    const forwardInvoice = (record) => {
      window.sessionStorage.setItem('id', record.id)
      window.sessionStorage.setItem('costs', record.kpje)
      proxy.$root.$router.push({ name: 'invoiceApply', params: { id: record.id, costs: record.kpje } })
    }

    const init = async ({ pageNum, pageSize }) => {
      window.sessionStorage.removeItem('kpxg')
      window.sessionStorage.removeItem('invoice_id')
      let user_id = window.sessionStorage.getItem('user-id')
      const res = await listOrder({ user_id, pageNum, pageSize, examine: 1, examine_yj: 1, examine_cost: 1, status: 0, invoiceStatus:1 })
      dataSource.value = res.list.list
      page.value.pageSize = res.list.pageSize,
      page.value.current = res.list.pageNum
      page.value.total = res.list.total
    }
    onMounted(init({ pageNum: 1, pageSize: 10 }))
    return {
      columns,
      dataSource,
      page,
      pagination,
      forwardInvoice

    }
  }
})
</script>
